@font-face {
	font-family: 'Samsung Sharp Sans';
	font-style: normal;
	font-weight: 300;
	src: local('Samsung Sharp Sans Regular'), url('../fonts/Samsung\ Sharp/samsungsharpsans.woff') format('woff');
}

@font-face {
	font-family: 'Samsung Sharp Sans';
	font-style: normal;
	font-weight: 400;
	src: local('Samsung Sharp Sans Medium'), url('../fonts/Samsung\ Sharp/samsungsharpsans-medium.woff') format('woff');
}


@font-face {
	font-family: 'Samsung Sharp Sans';
	font-style: normal;
	font-weight: 600;
	src: local('Samsung Sharp Sans Bold'), url('../fonts/Samsung\ Sharp/samsungsharpsans-bold.woff') format('woff');
}

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 300;
	src: local('Gilroy Light'), url('../fonts/Gilroy/gilroy-light.woff') format('woff');
}

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 400;
	src: local('Gilroy Regular'), url('../fonts/Gilroy/gilroy-regular.woff') format('woff');
}

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 500;
	src: local('Gilroy Medium'), url('../fonts/Gilroy/gilroy-medium.woff') format('woff');
}

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 600;
	src: local('Gilroy Bold'), url('../fonts/Gilroy/gilroy-bold.woff') format('woff');
}

/*
	Variables
*/
$primary: #362a50;
$secondary: #ffbd3b;
$text-primary: #362a50;
$text-secondary: #797979;


body {
	color: $text-primary;
	// font-family:'Samsung Sharp Sans';
	font-family: 'Gilroy';
	font-size: 18px;
	font-weight: 500;
}


/*
	Buttons 
*/
.btn {
	&.btn-primary {
		color: white;
		background-color: $primary;
		border-color: $primary;

		&:hover {
			opacity: 0.7;
		}
	}
}


/*
	Headers
*/
span.highlight {
	background-color: $secondary;
	padding-left: 10px;
	padding-right: 10px;
}

h1 {
	font-family: 'Samsung Sharp Sans';
	font-weight: 600;
	font-size: 60px;
	line-height: 90px;

}

h2 {
	font-family: 'Samsung Sharp Sans';
	font-weight: 600;
	font-size: 45px;
	line-height: 65px;
}

h5 {
	font-family: 'Gilroy';
	font-weight: 600;
	font-size: 22px;
}

.tagline-dark {
	font-size: 20px;
	font-weight: 600;
	font-family: 'Gilroy';
}


/*
	Text Utils
*/
.text-primary {
	color: $text-primary !important;
}

.text-secondary {
	color: $text-secondary !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-600 {
	font-weight: 600 !important;
}


/**
* Navigation
*/
.navbar {
	
	.navbar-nav {

		li.nav-item {

			margin-right: 35px;

			a.nav-link {
				font-size: 17px;
				color: $text-primary;

				&:hover {
					color: $secondary;
					text-decoration: underline;
				}
			}

		}
	}

}

/**
* Section
*/
.section {
	padding-top: 70px;
	padding-bottom: 70px;

	h2 {
		margin-bottom: 70px;
	}

	.side-image {
		width: 80%;
	}
}

/**
* Hero
*/
section.hero {

	.content {
		margin-top: 160px;
		margin-bottom: 160px;
	}

	.tagline {
		font-size: 18px;
	}

	.contact-btn {
		margin-top: 25px;
		font-weight: 500;
		font-size: 22px;
	}
}

.hero.home-hero {
	background: url("../images/backgrounds/hero.png");
	background-repeat: no-repeat;
	background-size: 70%;
	background-position-x: right;
}

section.services {
	background: $primary url("../images/backgrounds/services.png");

	.service-card {
		&:hover {
			background-color: #fff6e5;
			cursor: pointer;
		}
		
		img {
			margin-top: 30px;
			margin-bottom: 40px;
		}

		h5 {
			margin-bottom: 25px;
		}

		p.card-text {
			font-family: 'Gilroy';
			font-weight: 500;
			font-size: 15px;
		}
	}
}

section.about {
	padding-top: 100px;
	padding-bottom: 100px;
	background: url("../images/backgrounds/about-right.png") no-repeat center right /10%, url("../images/backgrounds/about-left.png") no-repeat bottom left /12%;

	i.las {
		font-size: 35px;
		margin-right: 15px;
	}
}

section.work {
	background: url("../images/backgrounds/work-right.png") no-repeat center right /15%, url("../images/backgrounds/work-left.png") no-repeat bottom left /20%;
	background-color: #fff6e4;

	.portfolio-card {
		border-radius: 0px;

		img {
			border-radius: 0px;
		}

		.card-body {
			border-top: solid 2px $secondary;

			.card-text {
				font-size: 18px;
			}
		}

		.card-footer {

			background-color: transparent;
			border: none;

			a {
				color: #db960e;
				text-decoration: none;
				font-weight: 600;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

}

section.testimonial {
	background-color: white;

	.carousel-controls {
		div {
			padding: 15px;
			cursor: pointer;
			font-size: 50px;
			color: $secondary;

			&:first-child {
				padding-left: 0;
			}
		}
	}

	.card {

		border: none;

		.card-body {
			background-color: #f4f5f7;

			.media-object {
				
				img {
					width: 80px;
					border-radius: 100%;
					border: solid 1px $primary;
				}

			}

		}
	}
}

section.contact-us {
	background: url("../images/backgrounds/work-right.png") no-repeat center right /15%, url("../images/backgrounds/work-left.png") no-repeat bottom left /10%;
	background-color: #fff6e4;

	.icon {
		width: 70px;
		height: 70px;
		line-height: 70px;
		background-color: #ffbd3b;
		text-align: center;
		border-radius: 50%;
		margin-right: 15px;
	}

	.input-group {

		border: 1px solid $secondary;
		border-radius: 10px;

		.input-group-text {
			border: none;
			background-color: white;
			padding-left: 25px;
			border-radius: 10px;

			i {
				font-size: 25px;
				color: $secondary;
			}
		}

		input {
			border: none;
			height: 60px;
			font-weight: 500;
			border-radius: 10px;
		}
	}

	.btn-primary {
		border-radius: 10px;
		font-weight: 500;
	}

}





/***********************************
*	PORTFOLIO PAGE SPECIFIC STYLES *
***********************************/
.portfolio-page{
	
	background-color: transparent;
	
	.hero {
		background: url("../images/backgrounds/portfolio-hero.png");
		background-repeat: no-repeat;
		background-size: 50%;
		background-position-x: right;
		background-position-y: center;
		border-bottom: solid 3px #EEE;
	}

	a {
		color: inherit;
		text-decoration: none;

		.portfolio-col {
			img {
				border-radius: 15px;
				margin-bottom: 15px;
				transition: all ease 0.3s;
	
				&:hover {
					filter: brightness(70%);
					cursor: pointer;
				}
			}
	
			h5 {
				font-size: 18px;
				cursor: pointer;
			}
	
			p {
				cursor: pointer;
				font-size: 18px;
				opacity: 0.7;
			}
		}

	}
}

.single-portfolio {
	.hero {

		padding-top: 50px;
		padding-bottom: 50px;
		background: url("../images/backgrounds/about-right.png") no-repeat top right /10%, url("../images/backgrounds/about-left.png") no-repeat center left /12%;

		p.tagline {
			font-size: 25px;
			line-height: 40px;
			margin-bottom: 50px;
		}

		img {
			border-radius: 20px;
			transition: all ease 0.3s;
			border: solid 1px #EEE;

			&:hover {
				filter: brightness(80%);
				cursor: pointer;
			}
		}

		p.description {
			font-size: 20px;
			color: $text-secondary;
			line-height: 35px;
		}

		.work-meta {

			.list-group {
				.list-group-item {
					border: none;
					border-top: solid 1px $primary;
					border-radius: 0;
					padding-bottom: 15px;
					padding-top: 15px;

					h5 {
						margin: 0;
						font-size: 20px;
						margin-top: 5px;
						font-weight: 600;

						a {
							color: $primary;
							text-decoration: none;

							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}

	.portfolio-testimonial {
		background: $primary;
	}
}

/*
	CONTACT PAGE
*/
.contact-page {
	
	background-color: transparent;
	
	.hero {
		background: url("../images/backgrounds/contact-hero.png");
		background-repeat: no-repeat;
		background-size: 50%;
		background-position-x: right;
		background-position-y: center;
	}

	.contact-info {

		margin-bottom: 25px;
		
		.card {
			
			background-color: #fff7e9;
			border-color: #ffe6b5;

			.icon {
				width: 70px;
				height: 70px;
				line-height: 70px;
				background-color: #ffbd3b;
				text-align: center;
				border-radius: 50%;
				margin-right: 15px;
			}

		}
	}
}





/*
	FOOTER
*/
footer {

	background-color: $primary;
	padding-bottom: 0 !important;

	ul.footer-links {
		li {
			list-style: none;
			padding: 0 20px;
			display: inline-block;
			margin-top: 10px;

			a {
				text-decoration: none;
				font-size: 18px;
				color: #fff;
				-webkit-transition: .5s all ease;
				-o-transition: .5s all ease;
				transition: .5s all ease;

				&:hover {
					color: $secondary;
				}
			}
		}
	}

	ul.footer-icons {
		li {
			list-style: none;
			display: inline-block;
			padding: 0 5px;
			margin-top: 5px;

			a {
				width: 50px;
				height: 50px;
				line-height: 54px;
				display: block;
				text-align: center;
				background-color: #41355a;
				color: $secondary;
				border-radius: 50%;
				-webkit-transition: .5s all ease;
				-o-transition: .5s all ease;
				transition: .5s all ease;

				&:hover {
					color: $primary;
					background-color: white;
				}
			}
		}
	}

	img {
		width: 200px;
	}

	.credit {
		border-top: solid 1px rgba(255,255,255,.2);
		color: $secondary;
	}
}